import { useState, useEffect } from 'react';
import HomePage from '../components/home_page/home';
import config from '../config/config.json';
import apiService, { newApiService } from '../services/apis';
import {
  jsonHandling,
  offerHandling,
  formatdata,
  checkIsMobile,
} from '../components/home_page/commonFunction';
// import {readJSON} from '../actions/apiendpointnames';
// import { service } from "../actions/middleware";
// import { loadJSDynamic } from "../actions/common";
import { checkSession } from '../actions/auth.service';

export default function Flights(props) {
  const [seoSectionOne, setSeoSectionOne] = useState(props.seoSectionOne);
  const [seoSectionTwo, setSeoSectionTwo] = useState(props.seoSectionTwo);
  const [seoSectionThree, setSeoSectionThree] = useState(props.seoSectionThree);
  const [SeoFlight, setSeoFlight] = useState(props.SeoFlight);
  const [DomesticFlight, setDomesticFlight] = useState(props.DomesticFlight);
  const [InternationalFlight, setInternationalFlight] = useState(
    props.InternationalFlight
  );
  const [aboutFirstSection, setAboutFirstSection] = useState(
    props.aboutFirstSection
  );
  const [aboutSeconfSection, setAboutSeconfSection] = useState(
    props.aboutSeconfSection
  );
  const [aboutJSON, setAbboutJSON] = useState(props.aboutJSON);
  const [whyBookW_IM, setWhyBookW_IM] = useState(props.whyBookW_IM);
  const [featuresJSON, setFeaturesJSON] = useState(props.featuresJSON);
  const [guidelines, setGuidelines] = useState(props.guidelines);
  const [downloadApp, setDownloadApp] = useState(props.downloadApp);
  const [searchWidgetJSON, setSearchWidgetJSON] = useState(
    props.searchWidgetJSON
  );
  const [offerDetails, setOfferDetails] = useState(props.offerDetails);
  const [didYouknow, setDidYouknow] = useState(props.didYouknow);
  const [markupFAQ, setMarkupFAQ] = useState(props.markupFAQ);
  const [componentPosition, setComponentPosition] = useState(
    props.componentPosition
  );
  // const [offers, setOffers] = useState(props.offers);
  const [searchType, setSearchType] = useState('UBA');
  const [isMobile, setIsMobile] = useState(false);
  const [faqData, setFaqData] = useState(props.faqData);
  const [ssr, setSsr] = useState(props.ssr);
  const [readMoreState, setReadMoreState] = useState(props.readMoreStates);
  const [recentSearch, setRecentSearch] = useState(props.recentSearch);
  const [earnMilesRecentSearch, setEarnMilesRecentSearch] = useState(
    props.earnMilesRecentSearch
  );
  const [redeemMilesRecentSearch, setRedeemMilesRecentSearch] = useState(
    props.redeemMilesRecentSearch
  );
  const [onwardFocus, setOnwardFocus] = useState(false);
  const [returnFocus, setReturnFocus] = useState(false);
  const [loginComponentJSON, setLoginComponentJSON] = useState(
    props?.loginComponentJSON
  );
  const [loggedIn_Info, setloggedIn_Info] = useState({ isLoggedIn: false });
  const [userTier, setUserTier] = useState('guest');
  const [resumeBookCookie, setresumeBookCookie] = useState(
    props.resumeBookCookie
  );
  const [breadCrumbData] = useState([
    {
      caption: 'Home',
      path: './',
      isReactRoute: false,
    },
    {
      caption: 'Domestic Flights',
      path: '',
      isReactRoute: false,
    },
  ]);
  useEffect(async () => {
    let data = await checkSession();
    setloggedIn_Info(data);
    setUserTier(!!data.tier ? data.tier.toLocaleLowerCase() : 'guest');
    // await loadJSDynamic(config.HeaderFooterLink.HeaderFooter).then(res=>{
    //     return loadJSDynamic(config.HeaderFooterLink.JPPLCommonGlobal)
    // })
  }, []);
  useEffect(() => {
    setIsMobile(window.screen.availWidth <= 1023);
  }, []);
  useEffect(() => {
    let componentStates = null;
    if (searchType == 'UBA') {
      componentStates = jsonHandling(
        props.EarnPageData,
        loggedIn_Info,
        window.screen.availWidth <= 1023,
        searchType,
        false,
        window.screen.availWidth
      );
    } else {
      componentStates = jsonHandling(
        props.RedeemPageData,
        loggedIn_Info,
        window.screen.availWidth <= 1023,
        searchType,
        false,
        window.screen.availWidth
      );
    }
    /* if (config.landingPageJSON.offerDependeny.includes(searchType)) {
      service(config.landingPageJSON[config?.landingPageJSON?.mockJsonEnabled ? 'mockJSONUrl' : 'homePageDataUrl'][searchType]?.offersURL, readJSON).then(offersData => {
        setOffers(offerHandling(offersData, isMobile));
      }).catch(err => {
        setOffers({
          flightOffer: false,
          showOffers: false,
          offerCarousal: false,
          didYouKnowSection: 0
        })
        logger(err, 'error');
      })
    } */
    if (componentStates != null) {
      if (ssr) {
        setRecentSearch(componentStates.recentSearch);
        setEarnMilesRecentSearch(componentStates.earnMilesRecentSearch);
        setRedeemMilesRecentSearch(componentStates.redeemMilesRecentSearch);
      }
      setSeoSectionOne(componentStates.seoSectionOne);
      setSeoSectionTwo(componentStates.seoSectionTwo);
      setSeoSectionThree(componentStates.seoSectionThree);
      setSeoFlight(componentStates.SeoFlight);
      setDomesticFlight(componentStates.DomesticFlight);
      setInternationalFlight(componentStates.InternationalFlight);
      setAboutFirstSection(componentStates.aboutFirstSection);
      setAboutSeconfSection(componentStates.aboutSeconfSection);
      setAbboutJSON(componentStates.aboutJSON);
      setWhyBookW_IM(componentStates.whyBookW_IM);
      setFeaturesJSON(componentStates.featuresJSON);
      setGuidelines(componentStates.guidelines);
      setDownloadApp(componentStates.downloadApp);
      setSearchWidgetJSON(componentStates.searchWidgetJSON);
      setOfferDetails(componentStates.offerDetails);
      setDidYouknow(componentStates.didYouknow);
      setMarkupFAQ(componentStates.markupFAQ);
      setComponentPosition(componentStates.componentPosition);
      setFaqData(componentStates.faqData);
      setReadMoreState(componentStates.readMoreStates);
      setLoginComponentJSON(componentStates.loginComponentJSON);
      setSsr(false);
      setresumeBookCookie(componentStates.resumeBookCookie);
    }
  }, [searchType, loggedIn_Info]);
  const readMoreView = (index, stateName) => {
    let updatedReadMore = { ...readMoreState };
    updatedReadMore[stateName][index].readMore =
      !updatedReadMore[stateName][index].readMore;
    setReadMoreState(updatedReadMore);
  };
  function overlayHandling(state, value) {
    if (state == 'onwardFocus') {
      setOnwardFocus(value);
    } else if (state == 'returnFocus') {
      setReturnFocus(value);
    }
  }
  //logger(searchType, 'searchType');
  return (
    <HomePage
      seoSectionOne={seoSectionOne}
      seoSectionTwo={seoSectionTwo}
      seoSectionThree={seoSectionThree}
      SeoFlight={SeoFlight}
      DomesticFlight={DomesticFlight}
      InternationalFlight={InternationalFlight}
      aboutFirstSection={aboutFirstSection}
      aboutSeconfSection={aboutSeconfSection}
      aboutJSON={aboutJSON}
      whyBookW_IM={whyBookW_IM}
      featuresJSON={featuresJSON}
      guidelines={guidelines}
      downloadApp={downloadApp}
      searchWidgetJSON={searchWidgetJSON}
      offerDetails={offerDetails}
      didYouknow={didYouknow}
      componentPosition={componentPosition}
      markupFAQ={markupFAQ}
      offers={props.offers}
      searchType={searchType}
      onSearchTypeToggle={(value) => setSearchType(value == 0 ? 'UBA' : 'UBR')}
      loggedIn_Info={loggedIn_Info}
      EarnPageData={props.EarnPageData}
      RedeemPageData={props.RedeemPageData}
      page={props.page}
      isMobile={isMobile}
      recentSearch={recentSearch}
      earnMilesRecentSearch={earnMilesRecentSearch}
      redeemMilesRecentSearch={redeemMilesRecentSearch}
      userTier={userTier}
      imageDomainUrl={props.imageDomainUrl}
      faqData={faqData}
      ssr={ssr}
      readMoreState={readMoreState}
      readMoreView={readMoreView}
      suggestedArray={props.suggestedArray}
      somethingWentWrong={props.somethingWentWrong}
      overlayHandling={overlayHandling}
      onwardFocus={onwardFocus}
      returnFocus={returnFocus}
      loginComponentJSON={loginComponentJSON}
      keywordContent={config.page_seo.home.flights}
      metaContent={config.page_seo.home.content.flights}
      resumeBookCookie={resumeBookCookie}
      breadCrumbData={breadCrumbData}
    />
  );
}
export async function getServerSideProps({ req }) {
  let isMobile = checkIsMobile(req.headers['user-agent']);
  let configUrlObject = config?.landingPageJSON?.mockJsonEnabled
    ? 'mockJSONUrl'
    : 'homePageDataUrl';
  let [EarnPageData, RedeemPageData, ubaOffers, ubrOffers, autoSuggest] =
    await Promise.all([
      apiService.readJSON({
        url: config.landingPageJSON[
          config?.landingPageJSON?.mockJsonEnabled
            ? 'mockJSONUrl'
            : 'homePageDataUrl'
        ].UBA.flightsDataURL,
      }),
      apiService.readJSON({
        url: config.landingPageJSON[
          config?.landingPageJSON?.mockJsonEnabled
            ? 'mockJSONUrl'
            : 'homePageDataUrl'
        ].UBR.flightsDataURL,
      }),
      apiService.readJSON({
        url: config.landingPageJSON[
          config?.landingPageJSON?.mockJsonEnabled
            ? 'mockJSONUrl'
            : 'homePageDataUrl'
        ].UBA?.offersURL,
        isOfferJSON: true,
      }),
      config.landingPageJSON[configUrlObject].UBR?.offersURL
        ? apiService.readJSON({
            url: config.landingPageJSON[configUrlObject].UBR?.offersURL,
            isOfferJSON: true,
          })
        : false,
      newApiService.autosuggest(''),
    ]);
  let suggestedArray = [];
  let somethingWentWrong = true;
  if (autoSuggest.length) {
    suggestedArray = formatdata(autoSuggest);
    somethingWentWrong = false;
  }
  return {
    props: {
      EarnPageData,
      RedeemPageData,
      page: 'Domestic Flights',
      offers: offerHandling(ubaOffers, ubrOffers, isMobile),
      ...jsonHandling(
        EarnPageData,
        { isLoggedIn: false },
        false,
        'UBA',
        true,
        1400
      ),
      imageDomainUrl: config.landingPageJSON.domainUrl,
      loader: true,
      ssr: true,
      suggestedArray,
      somethingWentWrong,
    },
  };
}
